import moment from 'moment';
import business from 'moment-business-days';

export function getFormattedDate(date: Date): string {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${year}${month >= 10 ? month : `0${month}`}${day >= 10 ? day : `0${day}`}`;
}

export function getStringToDateFormat({ date, m = -1 }: { date: string; m?: number }): Date {
  const year = date.substr(0, 4);
  const month = date.substr(4, 2);
  const day = date.substr(6, 2);

  return new Date(Number(year), Number(month) + m, Number(day));
}

export function setDateFormat(date: Date | string, format: string): string {
  if (!date) return '';
  let dateTypeDate;

  if (typeof date === 'string') dateTypeDate = getStringToDateFormat({ date: date });
  else dateTypeDate = date;

  return moment(dateTypeDate).format(format);
}

export function getDurationTimes(frtime: string, totime: string): moment.Duration {
  const fr = moment(moment(frtime).format('YYYY-MM-DD HH:mm:00'));
  const to = moment(moment(totime).format('YYYY-MM-DD HH:mm:00'));

  return moment.duration(to.diff(fr));
}

export function isDateInBetweenTwoDates(date: Date, frdt: Date, todt: Date): boolean {
  // 종료일 +1
  return !moment(date).isBefore(frdt) && moment(date).isBefore(moment(todt).add(1, 'd'));
}

export function getDayOfWeek(date: Date): string {
  const week = ['일', '월', '화', '수', '목', '금', '토'];

  return week[date.getDay()];
}

export function getDayDiffWeekDay(start: string, end: string, format: string): number {
  return business(start, format).businessDiff(moment(end, format)) + 1;
}
export function getDayDiff(start: string, end: string, format: string): number {
  return Math.abs(moment.duration(moment(start, format).diff(moment(end, format))).asDays()) + 1;
}
