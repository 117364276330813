import { ref } from 'vue';
import { $api, $loading } from '@/api/index';
import { modalController } from '@ionic/vue';
import { toast } from '@/utils/toast';

export function users() {
  const userInfo = ref();
  const isConnected = ref(true);
  const chk = ref(false);
  
  async function fetchUserInfos() {
    await $api(`/user`, 'GET')
      .then((res: any) => {
        userInfo.value = res.data;
        isConnected.value = true;
      })
      .catch(() => {
        isConnected.value = false;
      });
  }
  async function updateRrdate() {
    await $loading(`/user/extension`, 'post').then(
      (res: any) => {
        if (res.status == 200) {
          toast('비밀번호 만료일이 연장되었습니다.');
          modalController.dismiss(); 
        }
      }
    ).catch(err => {
      if (err.response.status == 406) {   
        alert(err.response.data.message);
      } else if (err.response.status == 500) {
        toast(
          '내부서버의 오류로 인해 현재 서비스의 상태가 원할하지 않습니다. 시스템 관리자에게 문의하시기 바랍니다.',
          5000
        );
      }
    });
  }
  async function updateUserInfo(resource: string, params: object) {
    await $loading(`/user/${resource}`, 'PUT', params)
      .then((res: any) => {
        if (res.status == 200) {    
          userInfo.value = res.data;
          isConnected.value = true;
          if(resource == 'pwd'){
            toast(
              '비밀번호가 변경되었습니다.',
              2000
            )
          }else if(resource == 'priv'){
            toast(
              '사용자(회사)명이 변경되었습니다.',
              2000
            )
          }else if(resource == 'email'){
            toast(
              '이메일이 변경되었습니다.',
              2000
            )
          }else{
            toast(
              '연락처가 변경되었습니다.',
              2000
            )
          }        
          modalController.dismiss();     
        }       
      })
      .catch(err => {
        if (err.response.status == 406) {   
          alert(err.response.data.message);
        } else if (err.response.status == 500) {
          toast(
            '내부서버의 오류로 인해 현재 서비스의 상태가 원할하지 않습니다. 시스템 관리자에게 문의하시기 바랍니다.',
            5000
          );
        }
      });
  }

  async function deafultPassword(userid: string, sano: string, email: string) {
    await $loading(`/user/deafult?userid=${userid}&sano=${sano}&email=${email}`, 'post').then(
      (res: any) => {
        if (res.status == 200) {
          toast('비밀번호가 초기화되었습니다.');
          chk.value = true;
        }
      }
    ).catch(err => {
      if (err.response.status == 406) {   
        alert(err.response.data.message);
      } else if (err.response.status == 500) {
        toast(
          '내부서버의 오류로 인해 현재 서비스의 상태가 원할하지 않습니다. 시스템 관리자에게 문의하시기 바랍니다.',
          5000
        );
      }
      chk.value = false;
    });
  }
 
  async function saveLog(id: string, pgid: string) {
    await $api(`/log?id=${id}&pgid=${pgid}`, 'POST').then(
      (res: any) => {
        res.data;
      }
    );
  }
  


  return { isConnected, userInfo, fetchUserInfos, updateUserInfo, updateRrdate, deafultPassword,chk, saveLog };
}
